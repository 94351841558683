import Button from "@/components/Common/Buttons/Button/Button";
import InputField from "@/components/Common/Inputs/Input/Input";
import GalleryMain from "@/components/Components/GalleryMain";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import axios from "axios";

export default {
    name: 'MovingCleaningPage',
    components: {
        GalleryMain,
        Button,
        InputField,
        TextArea
    },
    data() {
        return {
            serviceName: null,
            images: {
                moving: [],
                cleaning: [],
                interior: [],
            },
            form: {
                name: null,
                phone: null,
                email: null,
                message: null
            },
            fieldErrors: []
        }
    },
    methods: {
        translate(key) {
            return this.$t(this.serviceName + '-' + key)
        },
        transformServices(key) {
            return this.translate(key).split(';');
        },
        async sendMessage() {
            if (this.validateInputs() === false) {
                return;
            }

            try {
                await this.$store.dispatch('utils/openNotificationModal', {type: 'loading'});
                await axios.post('/api/contact/' + this.serviceName, this.form)
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'success',
                    message: 'Вашето съобщение беше изпратено успешно'
                });
            } catch (e) {
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'error',
                    message: 'Грешка! Моля, опитайте отново'
                });
            } finally {
                await this.$store.dispatch('utils/closeModal', 'loading');
                await this.$store.dispatch('utils/closeModal', 'forgottenPass');
            }
        },
        validateInputs() {
            this.fieldErrors = [];
            if (this.form.name === null || this.form.name === '' || this.form.name.length < 4) {
                this.fieldErrors.push('name');
            }
            if (this.form.phone === null || this.form.phone === '' || this.form.name.length < 4) {
                this.fieldErrors.push('phone');
            }
            if (this.form.email === null || this.form.email === '' || this.form.email.length < 4) {
                this.fieldErrors.push('email');
            }
            if (this.form.message === null || this.form.message === '' || this.form.message.length < 4) {
                this.fieldErrors.push('message');
            }

            return this.fieldErrors.length === 0;
        }
    },
    mounted() {
        switch (this.$route.name) {
            case 'MovingPage':
                this.serviceName = 'moving';
                break;
            case 'CleaningPage':
                this.serviceName = 'cleaning';
                break;
            case 'InteriorPage':
                this.serviceName = 'interior';
                break;
            default:
                this.$router.push({name: 'Index'})
                break;
        }
    },
}
