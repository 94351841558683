<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="" v-if="serviceName">
    <div class="moving-cleaning-title container">
      {{ translate('moving-heading-title') }}
    </div>
    <div class="moving-cleaning-heading-banner"
         :style="{ 'background-image': 'url(/static/moving-heading-banner.png' }">
      <div class="container">
        <div class="col-md-5 col-12 d-flex">
          <div class="heading-content-container">
            <div class="moving-cleaning-subtitle"> {{ translate('subtitle') }}</div>
            <div class="moving-cleaning-description">{{ translate('description') }}</div>
            <div class="col-md-6 col-12">
              <Button :theme="'solid-orange'" :size="'medium'" :text="translate('request-service')"/>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="container">
      <div class="row ">
        <div class="col-md-4 col-12 ">
          <div class="service-wrapper">
            <div class="service-title"> {{ translate('highlight-1') }}</div>
            <div class="service-description"> {{ translate('highlight-1-description-service') }}</div>
          </div>
        </div>
        <div class="col-md-4 col-12 ">
          <div class="service-wrapper">
            <div class="service-title"> {{ translate('highlight-2') }}</div>
            <div class="service-description"> {{ translate('highlight-2-description-service') }}</div>
          </div>
        </div>
        <div class="col-md-4 col-12 ">
          <div class="service-wrapper">
            <div class="service-title"> {{ translate('highlight-3') }}</div>
            <div class="service-description"> {{ translate('highlight-3-description-service') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="process-wrapper">
      <div class="container">
        <div class="process-title"> {{ translate('whats-the-process-title')}} </div>
        <div class="row">
          <div class="col-md-6 process-description"> {{ translate('process-description-left')}}</div>
          <div class="col-md-6 process-description"> {{ translate('process-description-right')}}</div>
        </div>
      </div>

    </div>
    <div class="our-services-wrapper">
      <div class="container">
        <div class="our-services-title">{{ translate('our-services') }}</div>
        <div class="d-flex table-row table-rows" v-for="item in transformServices('service-list')">
          <div class="me-auto"> {{ $t(item) }}</div>
        </div>
      </div>

    </div>
    <div class="our-services-wrapper">
      <div class="container">
        <div class="our-services-title">{{ translate('gallery') }}</div>
        <div class="" >
          <GalleryMain :images="images[serviceName]"/>
        </div>
      </div>

    </div>
    <div class="our-services-wrapper">
      <div class="container">
        <div class="our-services-title">{{ translate('request-moving') }}</div>

        <div class="" >

          <div class="col-md-7  contacts-right-wrapper">
            <div class="contact-description"> {{ translate('contact-paragraph') }}</div>
            <div class="row ">
              <div class="col-md-4 input-items">
                <InputField
                    :inputtype="'text'"
                    v-model="form.name"
                    :content="form.name"
                    :label="translate('your-name')"
                    :error="fieldErrors.includes('name')"
                />
              </div>
              <div class="col-md-4 input-items">
                <InputField
                    :inputtype="'text'"
                    v-model="form.phone"
                    :content="form.phone"
                    :label="translate('your-number')"
                    :error="fieldErrors.includes('phone')"
                />
              </div>
              <div class="col-md-4 input-items">
                <InputField
                    :inputtype="'text'"
                    v-model="form.email"
                    :content="form.email"
                    :label="'Email'"
                    :error="fieldErrors.includes('email')"
                />
              </div>
            </div>
            <div class="row input-items text-area">
              <TextArea
                  :label="translate('your-message')"
                  :content="form.message"
                  v-model="form.message"
                  :error="fieldErrors.includes('message')"
              />
            </div>
            <div class="col-md-5 button-send">
              <Button :text="translate('send')" @click="sendMessage()" :size="'medium'" :theme="'solid-orange'"/>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>


  <div>
    <Footer/>
  </div>
</template>

